import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'components';
import { tablet } from 'styles/breakpoints';

const Button = styled(PrimaryButton)`
  max-width: 28.75rem;
  width: 100%;

  @media ${tablet} {
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 50%; /* Adjust the height as needed */
      background: linear-gradient(
        1deg,
        rgba(255, 255, 255, 0.9) 1%,
        rgba(255, 255, 255, 0.9) 98.64%
      );
      filter: blur(4px);
      z-index: -1; /* Ensure the pseudo-element is behind the content */
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%; /* Adjust the height as needed */
      background: #ffffff; /* Full color, no transparency */
      z-index: -1; /* Ensure the pseudo-element is behind the content */
    }
  }
`;

const ContinueButton = (props: ComponentProps<typeof PrimaryButton>) => (
  <Wrapper>
    <Button {...props}>{props?.title ?? 'Continue'}</Button>
  </Wrapper>
);

export default ContinueButton;
